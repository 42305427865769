import { CHAIN_LIST, IChainConfig } from '../types';

const ETH_DECIMALS = 18;

export const chainsConfig: Record<CHAIN_LIST, IChainConfig> = {
  [CHAIN_LIST.tacTestnet]: {
    chainId: '0x956',
    chainName: 'TAC Turin',
    nativeCurrency: {
      name: 'TAC',
      symbol: 'TAC',
      decimals: ETH_DECIMALS,
    },
    rpcUrls: [
      'https://newyork-inap-72-251-230-233.ankr.com:443/tac_tacd_testnet_full_rpc_1',
    ],
    blockExplorerUrls: ['https://explorer.tac-turin.ankr.com/'],
  },
};
